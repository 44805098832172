<script setup lang="ts">
import { useAuthModalStore } from '@glow/shared/features/auth';
import { CloseIcon } from '@glow/shared/components/icons';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { showAuthModal } = useAuthModalStore();
</script>

<template>
  <div class="d-flex flex-column gap-4">
    <div class="d-flex gap-3 align-items-start">
      <div class="flex-grow-1">
        Войдите, чтобы&nbsp;записываться на&nbsp;услуги мастера
        и&nbsp;отслеживать предстоящие записи
      </div>
      <AppButton
        variant="link"
        :icon-start="CloseIcon"
        icon-start-color="secondary"
        @click="emit('close')"
      />
    </div>
    <AppButton fluid @click="showAuthModal">
      Войти или зарегистрироваться
    </AppButton>
  </div>
</template>
